import React, { Component, Fragment } from "react";
import { Button, Spinner, Table } from "reactstrap";
import {
  AC_TOAST_ID,
  DAC_TOAST_ID,
  SECTION_AUDITED_USERS,
  SECTION_UNAUDITED_USERS,
  TITLE_STH_WENT_WRONG,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  UI_STATE_EMPTY,
  UI_STATE_FAILURE,
  UI_STATE_LOADING,
  UI_STATE_SUCCESS,
  USER_STATUS_APPROVED,
  USER_STATUS_REJECTED,
} from "../../constants/strings";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  getAuditedUsers,
  getUnauditedUsers,
  updateUserStatus,
} from "./access-control.api";
import Search from "../../components/Search/search.component";
import FloatingLoader from "../../components/FloatingLoader";
import TablePagination from "../../components/TablePagination/table-pagination.component";
import "./access-control.scss";
import { getCurrentDate } from "../../utils/DateTime";
import { sortArrayOfObjectsByTimestamp } from "../../utils/ArrayManipulation";
import { checkUserInCognitoGroup, getSession } from "../../utils/AuthUtils";
import Multiselect from "multiselect-react-dropdown";
import common from "../../apis/common";
import { toast } from "react-toastify";
import { handleAccessToggle } from "../DownloadAccessControl/download-access-control.api";
import ToggleSwitch from "../../components/ToggleSwitch";

const minDate = getCurrentDate("2021-01-08+00:00:00");
class AccessControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      restriction: [
        { category_name: "Full Restriction", id: 1 },
        { category_name: "Partial Restriction", id: 2 },
        // { category_name: 'No Restriction', id: 3 },
      ],
      rows: [],
      index: null,
      audited_rows: [],
      unaudited: {
        data: {},
        filters: {
          searchBy: "",
          dateMin: "",
          dateMax: "",
        },
        pagination: {
          pagedBy: 1,
          recordsPerPage: 10,
        },
        viewState: UI_STATE_EMPTY,
        apiCallInProgress: false,
      },
      audited: {
        data: {},
        filters: {
          searchBy: "",
          dateMin: "",
          dateMax: "",
        },
        pagination: {
          pagedBy: 1,
          recordsPerPage: 10,
        },
        viewState: UI_STATE_EMPTY,
        apiCallInProgress: false,
      },
      updatingUserStatus: false,
    };

    this.multiselectRef = React.createRef();
    this.floatingLoaderRef = React.createRef();
  }

  async getSuggestions() {
    let session = await getSession();
    if (!session) {
      return [];
    }
    const payload = {
      suggestion_text: "",
      suggestion_type: ["sector"],
      page: "document_search",
      tab: "all",
    };
    const { data } = await common.get("/get_suggestions", {
      params: {
        ...payload,
        sectors: ["ALL"],
      },
      headers: { Authorization: session.accessToken.jwtToken },
    });
    this.setState({ options: data?.data });
  }

  componentDidMount() {
    if (
      !this.props.auth.user ||
      !checkUserInCognitoGroup(this.props.auth.user, "signup-admins")
    ) {
      this.props.history.push("/document_search");
    }

    this.getUnauditedUsers();
    this.getAuditedUsers();
    this.getSuggestions();
  }

  getUnauditedUsers() {
    const unaudited = { ...this.state.unaudited };
    unaudited.viewState = UI_STATE_LOADING;
    this.setState({ unaudited });

    // TODO: Change this to dynamic/user selected date
    unaudited.filters.dateMin = minDate;
    unaudited.filters.dateMax = getCurrentDate("YYYY-MM-DD+HH:mm:ss");

    getUnauditedUsers(unaudited.filters, unaudited.pagination)
      .then(this.onGetUnauditedUsersSuccess)
      .catch(this.onGetUnauditedUsersFailure);
  }

  handleToggle = (state, email, download_enabled) => {
    let payload = {
      username: email,
      flag: download_enabled ? 0 : 1,
    };
    handleAccessToggle(payload)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            this.props.toastify.fire(
              res.data.success_message,
              DAC_TOAST_ID,
              TOAST_TYPE_SUCCESS
            );
          } else {
            this.props.toastify.fire(
              res.data.error_message,
              DAC_TOAST_ID,
              TOAST_TYPE_ERROR
            );
          }
        } else {
          this.props.toastify.fire(
            TITLE_STH_WENT_WRONG,
            DAC_TOAST_ID,
            TOAST_TYPE_ERROR
          );
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.toastify.fire(
          TITLE_STH_WENT_WRONG,
          DAC_TOAST_ID,
          TOAST_TYPE_ERROR
        );
      });
  };
  onGetUnauditedUsersSuccess = (res) => {
    const unaudited = { ...this.state.unaudited };

    if (res.status === 200 && res.data.status) {
      if (res.data.data.records.length) {
        unaudited.data = res.data.data;
        unaudited.data.records = sortArrayOfObjectsByTimestamp(
          unaudited.data.records,
          "last_modified_at",
          "desc"
        );
        unaudited.viewState = UI_STATE_SUCCESS;
      } else {
        unaudited.viewState = UI_STATE_EMPTY;
      }

      this.setState({ unaudited });
    } else {
      unaudited.viewState = UI_STATE_FAILURE;
      this.setState({ unaudited });
    }
  };

  onGetUnauditedUsersFailure = (error) => {
    console.error(error);
    const unaudited = { ...this.state.unaudited };
    unaudited.viewState = UI_STATE_FAILURE;
    this.setState({ unaudited });
  };

  getAuditedUsers() {
    const audited = { ...this.state.audited };
    audited.viewState = UI_STATE_LOADING;
    this.setState({ audited });

    // TODO: Change this to dynamic/user selected date
    audited.filters.dateMin = minDate;
    audited.filters.dateMax = getCurrentDate("YYYY-MM-DD+HH:mm:ss");

    getAuditedUsers(audited.filters, audited.pagination)
      .then(this.onGetAuditedUsersSuccess)
      .catch(this.onGetAuditedUsersFailure);
  }

  onGetAuditedUsersSuccess = (res) => {
    const audited = { ...this.state.audited };

    if (res.status === 200 && res.data.status) {
      if (res.data.data.records.length) {
        audited.data = res.data.data;
        audited.data.records = sortArrayOfObjectsByTimestamp(
          audited.data.records,
          "last_modified_at",
          "desc"
        );
        audited.viewState = UI_STATE_SUCCESS;
      } else {
        audited.viewState = UI_STATE_EMPTY;
      }

      this.setState({ audited });
      if (audited?.data?.records?.length > 0) {
        let raw = [];
        audited?.data?.records?.forEach((record, index) => {
          raw.push({
            index: index,
            selected: record?.sectors?.map((d) => {
              return {
                category_name: d,
              };
            }),
            restriction_selected: [
              {
                category_name: record?.restriction,
              },
            ],
          });
        });
        this.setState({ audited_rows: raw });
      }
    } else {
      audited.viewState = UI_STATE_FAILURE;
      this.setState({ audited });
    }
  };
  onGetAuditedUsersFailure = (error) => {
    console.error(error);
    const audited = { ...this.state.audited };
    audited.viewState = UI_STATE_FAILURE;
    this.setState({ audited });
  };
  setSelected = (selectedList, index) => {
    const { rows } = this.state;
    const updatedRows = [...rows];
    const existingRow = updatedRows.find((row) => row.index === index);
    if (existingRow) {
      existingRow.selected = selectedList;
    } else {
      const newRow = {
        index,
        selected: selectedList,
        restriction_selected: [],
        flag: 1,
      };
      updatedRows.push(newRow);
    }
    this.setState({ rows: updatedRows });
  };
  setSelectedRestriction = (selectedList, index) => {
    const { rows } = this.state;
    const updatedRows = [...rows];
    const existingRow = updatedRows.find((row) => row.index === index);
    if (existingRow) {
      existingRow.restriction_selected = selectedList;
    } else {
      const newRow = {
        index,
        selected: [],
        restriction_selected: selectedList,
        flag: 1,
      };
      updatedRows.push(newRow);
    }
    this.setState({ rows: updatedRows });
  };
  setDownloadAccess = (download_enabled, index) => {
    const { rows } = this.state;
    const updatedRows = [...rows];
    const existingRow = updatedRows.find((row) => row.index === index);
    if (existingRow) {
      existingRow.flag = download_enabled;
    } else {
      const newRow = {
        index,
        selected: [],
        restriction_selected: [],
        flag: download_enabled,
      };
      updatedRows.push(newRow);
    }
    this.setState({ rows: updatedRows });
  };
  setSelectedAudited = (selectedList, index) => {
    const { audited_rows } = this.state;
    const updatedRows = [...audited_rows];
    const existingRow = updatedRows.find((row) => row.index === index);
    if (existingRow) {
      existingRow.selected = selectedList;
    } else {
      const newRow = {
        index,
        selected: selectedList,
        restriction_selected: [],
      };
      updatedRows.push(newRow);
    }
    this.setState({ audited_rows: updatedRows });
  };
  setSelectedRestictionAudited = (selectedList, index) => {
    const { audited_rows } = this.state;
    const updatedRows = [...audited_rows];
    const existingRow = updatedRows.find((row) => row.index === index);
    if (existingRow) {
      existingRow.restriction_selected = selectedList;
    } else {
      const newRow = {
        index,
        selected: [],
        restriction_selected: selectedList,
      };
      updatedRows.push(newRow);
    }
    this.setState({ audited_rows: updatedRows });
  };
  setDownloadAccessAudited = (download_enabled, index) => {
    const { audited_rows } = this.state;
    const updatedRows = [...audited_rows];
    const existingRow = updatedRows.find((row) => row.index === index);
    if (existingRow) {
      existingRow.flag = download_enabled;
    } else {
      const newRow = {
        index,
        selected: [],
        restriction_selected: [],
        flag: download_enabled,
      };
      updatedRows.push(newRow);
    }
    this.setState({ audited_rows: updatedRows });
  };
  approveUser = (data, index, type) => {
    const { email, download_enabled = null } = data;
    let rows = [];
    if (type === 1) rows = this.state.rows;
    else rows = this.state.audited_rows;
    const updatedRows = [...rows];
    const existingRow = updatedRows.find((row) => row.index === index);
    this.updateUserStatus(
      email,
      USER_STATUS_APPROVED,
      existingRow,
      download_enabled
    );
  };
  rejectUser = (data, index, type) => {
    const { email, download_enabled = null } = data;
    let rows = [],
      existingRow;
    if (type === 1) {
      //table 1
      rows = this.state.rows;
      existingRow = undefined;
    } //audit histroi table
    else {
      rows = this.state.audited_rows;
      const updatedRows = [...rows];
      existingRow = updatedRows.find((row) => row.index === index);
      if (!existingRow) {
        existingRow = {
          selected: data?.sectors?.map((d) => {
            return {
              category_name: d,
            };
          }),
          restriction_selected: [
            {
              category_name: data?.restriction,
            },
          ],
        };
      }
    }
    this.updateUserStatus(
      email,
      USER_STATUS_REJECTED,
      existingRow,
      download_enabled
    );
  };
  updateAuditHistoryUserUser = (data, index) => {
    const { email, download_enabled = null } = data;
    const { audited_rows } = this.state;
    const updatedRows = [...audited_rows];
    const existingRow = updatedRows.find((row) => row.index === index);
    this.updateUserStatus(
      email,
      USER_STATUS_APPROVED,
      existingRow,
      download_enabled
    );
  };
  updateUserStatus(email, status, existingRow, download_enabled) {
    let { updatingUserStatus } = this.state;
    let array = {};
    if (!existingRow) {
      array = {
        sectors: ["ALL"],
        restriction_selected: ["No Restriction"],
        flag: 1,
      };
    } else {
      array = {
        sectors:
          existingRow?.selected
            ?.map((d) => d.category_name)
            ?.filter((d) => d !== "ALL") || [],
        restriction_selected:
          existingRow?.restriction_selected?.map((d) => d.category_name) || [],
        flag:
          existingRow?.flag != null
            ? existingRow?.flag
              ? 1
              : 0
            : download_enabled != null
            ? download_enabled
            : 0,
      };
      if (array?.sectors?.length <= 0) {
        array.sectors = ["ALL"];
        array.restriction_selected = ["No Restriction"];
      } else {
        if (array?.restriction_selected?.length <= 0) {
          toast.error("Please select a restriction");
          return;
        } else {
          if (array?.restriction_selected?.[0] === "No Restriction") {
            toast.error("Please select a restriction");
            return;
          }
        }
      }
    }
    if (!updatingUserStatus) {
      updatingUserStatus = true;
      this.setState({ updatingUserStatus });
      this.floatingLoaderRef.current.toggle(true);
      if (existingRow) {
        this.setState({ index: existingRow?.index });
      }
      updateUserStatus(
        email,
        status,
        array?.sectors,
        array?.restriction_selected?.[0],
        array?.flag
      )
        .then(this.onUpdateUserStatusSuccess)
        .catch(this.onUpdateUserStatusFailure);
    }
  }

  onUpdateUserStatusSuccess = (res) => {
    this.setState({ updatingUserStatus: false });
    this.floatingLoaderRef.current.toggle(false);

    if (res.status === 200 && res.data.status) {
      if (this.state.index != null) {
        const rows = this.state.rows.filter(
          (item) => item.index !== this.state.index
        );
        this.setState({ rows });
        this.setState({ index: null });
      }
      this.props.toastify.fire(
        res.data.message || res.success_message,
        AC_TOAST_ID,
        TOAST_TYPE_SUCCESS
      );
      setTimeout(() => {
        this.getUnauditedUsers();
        this.getAuditedUsers();
      }, 2000);
    } else {
      this.props.toastify.fire(
        res.data.error_message ||
          "Could not update User Status. Please try again.",
        AC_TOAST_ID,
        TOAST_TYPE_SUCCESS
      );
    }
  };

  onUpdateUserStatusFailure = (error) => {
    this.setState({ updatingUserStatus: false });
    this.floatingLoaderRef.current.toggle(false);
    console.error(error);
    this.props.toastify.fire(
      "Network error!. Could not update User Status. Please try again.",
      AC_TOAST_ID,
      TOAST_TYPE_ERROR
    );
  };

  getJSXForUIState(section) {
    switch (this.state[section].viewState) {
      case UI_STATE_LOADING:
        return (
          <div className="ui-state loading">
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="secondary" />
          </div>
        );
      case UI_STATE_SUCCESS:
        const tableJSX = this.getJSXForSectionTable(section);

        return <div className="ui-state success">{tableJSX}</div>;
      case UI_STATE_FAILURE:
        return (
          <div className="ui-state fail">
            {/*<FontAwesomeIcon*/}
            {/*    icon="exclamation-circle"*/}
            {/*    className="ico"*/}
            {/*/>*/}
            <h6 className="placeholder-text">{/*SOMETHING WENT WRONG*/}</h6>
          </div>
        );
      case UI_STATE_EMPTY:
        return (
          <div className="ui-state empty">
            {/*<FontAwesomeIcon*/}
            {/*    icon="info-circle"*/}
            {/*    className="ico"*/}
            {/*/>*/}
            <h6 className="placeholder-text">{/*NO RECORDS FOUND*/}</h6>
          </div>
        );
      default:
        return null;
    }
  }

  getJSXForSectionTable(section) {
    switch (section) {
      case SECTION_UNAUDITED_USERS:
        return this.getJSXForUnauditedUsersTable(this.state.unaudited.data);
      case SECTION_AUDITED_USERS:
        return this.getJSXForAuditedUsersTable(this.state.audited.data);
      default:
        return null;
    }
  }

  getJSXForActionBtnInAuditedUsersTable(data, status, index) {
    if (status.toLowerCase() === "approved") {
      return (
        <div className="d-flex">
          <Button
            className="access-control__section__action-btn disable"
            onClick={() => this.updateAuditHistoryUserUser(data, index)}
            color="success"
            active
          >
            Update
          </Button>
          <Button
            className="access-control__section__action-btn disable ml-2"
            onClick={() => this.rejectUser(data, index, 2)}
            color="danger"
            active
          >
            Disable
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          className="access-control__section__action-btn enable"
          onClick={() => this.approveUser(data, index, 2)}
          color="success"
          active
        >
          Enable
        </Button>
      );
    }
  }

  getJSXForAuditedUsersTable(data) {
    const tableRowsJSX = [];

    data.records.forEach((elem, index) => {
      tableRowsJSX.push(
        <tr key={`unaudited-user-row-${index}`}>
          <td>{elem.name}</td>
          <td>{elem.organisation}</td>
          <td>{elem.email}</td>
          <td>{elem.contact_number}</td>
          <td>{elem.status}</td>
          <td>
            <Multiselect
              options={this.state.options}
              selectedValues={elem.sectors?.map((d) => {
                return {
                  category_name: d,
                };
              })}
              disable={elem.status !== USER_STATUS_APPROVED ? true : false}
              displayValue="category_name"
              onSelect={(selectedList) =>
                this.setSelectedAudited(selectedList, index)
              }
              onRemove={(selectedList) =>
                this.setSelectedAudited(selectedList, index)
              }
            />
          </td>
          <td>
            {elem.status === USER_STATUS_APPROVED ? (
              <Multiselect
                singleSelect={true}
                options={this.state.restriction}
                selectedValues={[
                  {
                    category_name: elem?.restriction,
                  },
                ]}
                disable={elem.status !== USER_STATUS_APPROVED ? true : false}
                displayValue="category_name"
                onSelect={(selectedList) =>
                  this.setSelectedRestictionAudited(selectedList, index)
                }
                onRemove={(selectedList) =>
                  this.setSelectedRestictionAudited(selectedList, index)
                }
              />
            ) : (
              elem?.restriction || "NA"
            )}
          </td>
          <td>
            <ToggleSwitch
              disabled={elem.status !== USER_STATUS_APPROVED ? true : false}
              switchState={(state) =>
                this.setDownloadAccessAudited(state, index)
              }
              checked={
                this.state.rows.find((row) => row.index === index)?.flag ||
                elem?.download_enabled
              }
            />
          </td>
          <td>{elem.last_modified_by}</td>
          <td>{elem.last_modified_at}</td>
          <td>
            {this.getJSXForActionBtnInAuditedUsersTable(
              elem,
              elem.status,
              index
            )}
          </td>
        </tr>
      );
    });

    return (
      <Fragment>
        <Table hover responsive borderless>
          <thead>
            <tr>
              <th>Name</th>
              <th>Organization</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Current Status</th>
              <th>Selected Sectors</th>
              <th>Restriction</th>
              <th>Download Report</th>
              <th>Last Modified By</th>
              <th>Last Modified At</th>
              <th>Change Status</th>
            </tr>
          </thead>
          <tbody>{tableRowsJSX}</tbody>
        </Table>
      </Fragment>
    );
  }

  getJSXForUnauditedUsersTable(data) {
    const tableRowsJSX = [];
    data.records.forEach((elem, index) => {
      tableRowsJSX.push(
        <tr key={`unaudited-user-row-${index}`}>
          <td>{elem.name}</td>
          <td>{elem.organisation}</td>
          <td>{elem.email}</td>
          <td>{elem.contact_number}</td>
          <td>{elem.last_modified_at}</td>
          <td>
            <Multiselect
              options={this.state.options}
              displayValue="category_name"
              onSelect={(selectedList) => this.setSelected(selectedList, index)}
              onRemove={(selectedList) => this.setSelected(selectedList, index)}
            />
          </td>
          <td>
            <Multiselect
              singleSelect={true}
              disable={
                this.state.rows.find((row) => row.index === index)?.selected
                  ?.length > 0
                  ? false
                  : true
              }
              options={this.state.restriction}
              displayValue="category_name"
              onSelect={(selectedList) =>
                this.setSelectedRestriction(selectedList, index)
              }
              onRemove={(selectedList) =>
                this.setSelectedRestriction(selectedList, index)
              }
            />
          </td>
          <td>
            <ToggleSwitch
              switchState={(state) => this.setDownloadAccess(state, index)}
              checked={
                this.state.rows.find((row) => row.index === index)?.flag || true
              }
            />
          </td>
          <td className="actions">
            <Button
              className="access-control__section__action-btn approve"
              onClick={() => this.approveUser(elem, index, 1)}
              color="success"
              active
            >
              Approve
            </Button>
          </td>
          <td className="actions">
            <Button
              className="access-control__section__action-btn reject"
              onClick={() => this.rejectUser(elem, index, 1)}
              color="danger"
              active
            >
              Reject
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <Fragment>
        <Table hover responsive borderless>
          <thead>
            <tr>
              <th>Name</th>
              <th>Organization</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Signed Up At</th>
              <th>Select Sectors</th>
              <th>Select Restriction</th>
              <th>Download Report</th>
              <th className="actions">Actions</th>
              <th className="actions"></th>
            </tr>
          </thead>
          <tbody>{tableRowsJSX}</tbody>
        </Table>
      </Fragment>
    );
  }

  search = (searchBy, section) => {
    switch (section) {
      case SECTION_UNAUDITED_USERS:
        const unaudited = { ...this.state.unaudited };
        unaudited.filters.searchBy = searchBy;
        unaudited.pagination.pagedBy = 1;
        this.setState({ unaudited });
        this.getUnauditedUsers();
        break;
      case SECTION_AUDITED_USERS:
        const audited = { ...this.state.audited };
        audited.filters.searchBy = searchBy;
        audited.pagination.pagedBy = 1;
        this.setState({ audited });
        this.getAuditedUsers();
        break;
      default:
        return null;
    }
  };

  paginate = (pageNum, section) => {
    const { unaudited, audited } = this.state;

    switch (section) {
      case SECTION_UNAUDITED_USERS:
        unaudited.pagination.pagedBy = pageNum;
        this.setState({ unaudited });
        this.getUnauditedUsers();
        break;
      case SECTION_AUDITED_USERS:
        audited.pagination.pagedBy = pageNum;
        this.setState({ audited });
        this.getAuditedUsers();
        break;
      default:
        return null;
    }
  };

  render() {
    const { unaudited, audited } = this.state;
    const uiStateUnauditedUsers = this.getJSXForUIState(
      SECTION_UNAUDITED_USERS
    );
    const uiStateAuditedUsers = this.getJSXForUIState(SECTION_AUDITED_USERS);
    const totalRecordsUnaudited = unaudited.data.total_records || 0;
    const totalPagesUnaudited = Math.ceil(
      totalRecordsUnaudited / unaudited.pagination.recordsPerPage
    );
    const totalRecordsAudited = audited.data.total_records || 0;
    const totalPagesAudited = Math.ceil(
      totalRecordsAudited / audited.pagination.recordsPerPage
    );
    return (
      <Fragment>
        <div className="access-control-page">
          <div className="access-control-page__inner-container spark-card">
            <div className="access-control-page__section unaudited">
              <h4 className="access-control-page__section__title text-center">
                Generic Ids – Approval / Rejection list
              </h4>
              <Search
                section={SECTION_UNAUDITED_USERS}
                onSubmit={this.search}
                onReset={this.search}
                placeholder="Search by Name, Organization, Email Address or Contact Number"
              />
              {uiStateUnauditedUsers}
              {totalPagesUnaudited > 0 && (
                <TablePagination
                  paginationEnterHandler={this.paginate}
                  navBtnClickHandler={this.paginate}
                  section={SECTION_UNAUDITED_USERS}
                  currentPage={unaudited.pagination.pagedBy}
                  totalPages={totalPagesUnaudited}
                  totalResults={totalRecordsUnaudited}
                  size={unaudited.pagination.size}
                />
              )}
            </div>

            <hr />

            <div className="access-control-page__section audited">
              <h4 className="access-control-page__section__title">
                Audit History
              </h4>
              <Search
                section={SECTION_AUDITED_USERS}
                onSubmit={this.search}
                onReset={this.search}
                placeholder="Search by Name, Organization, Email Address or Contact Number"
              />
              {uiStateAuditedUsers}
              {totalPagesAudited > 0 && (
                <TablePagination
                  paginationEnterHandler={this.paginate}
                  navBtnClickHandler={this.paginate}
                  section={SECTION_AUDITED_USERS}
                  currentPage={audited.pagination.pagedBy}
                  totalPages={totalPagesAudited}
                  totalResults={totalRecordsAudited}
                  size={audited.pagination.size}
                />
              )}
            </div>
          </div>
        </div>
        <FloatingLoader
          ref={this.floatingLoaderRef}
          message="Downloading"
          xPos="center"
          yPos="center"
        />
      </Fragment>
    );
  }
}

export default AccessControl;
