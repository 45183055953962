import React from "react";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DATE_FORMAT,
  DS_FILTER_SNIPPETS,
  DS_FILTER_SORT_DATE,
  DS_FILTER_SORT_RELEVANCE,
  /* DS_RESULTS_TITLE_MAXLEN, */
} from "../../constants/strings";
import { observer } from "mobx-react";
// import { truncateWithElipsis } from "../../utils/StringManipulation";
import { formatDate } from "../../utils/DateTime";
import DropdownSelect from "../DropdownSelect";
import SparkCard from "../SparkCard";
import find from "lodash.find";
import "./style.scss";
import Pagination from "../Pagination";
// import { tidyHTML } from "../../utils/HTMLValidation";
import ReactHtmlParser from "react-html-parser";
import ReactTooltip from "react-tooltip";

class ResultViewer extends React.Component {
  constructor(props) {
    super(props);
    this.loaderStateElemRef = React.createRef();
    this.successStateElemRef = React.createRef();
    this.failStateElemRef = React.createRef();
    this.emptyStateElemRef = React.createRef();
    this.resultsCountFigureElemRef = React.createRef();
    this.state = {
      dropdownOpen: false,
      activeIndex: 0,
      activePlayer: null,
      pageInputValue: 0,
      randomID: String(Math.random()),
    };
    this.totalResults = 0;
    this.totalPages = 0;
    this.currentPage = 1;
  }

  //Refs
  sortDropdownBtnRef = React.createRef();

  //Configs for child components
  resultViewerCardConfig = {
    header: {
      show: true,
      content: null,
    },
    body: {
      content: null,
    },
    footer: {
      show: false,
      content: null,
    },
  };
  sortDropdownBtnConfig = {
    defaultSelection: "Date",
    selections: [
      {
        key: DS_FILTER_SORT_DATE,
        label: "Date",
      },
      {
        key: DS_FILTER_SORT_RELEVANCE,
        label: "Relevance",
      },
    ],
  };

  //Lifecycle methods
  componentDidMount() {}

  /**
   * @desc When a user clicks on the result list item
   * the series of events which take place are defined here
   *
   * @param {event} e - React synthetic event
   */
  onResultListItemClick = (e) => {
    try {
      let targetObj = find(
        this.props.store.resultViewer.results.documents.hits,
        function (result) {
          return result.document_id === e.currentTarget.dataset.id;
        }
      );
      if (targetObj.report_type === "Podcast") {
        this.props.onClickOnPodcast(targetObj);
        return;
      }
      let snippets = targetObj.hasOwnProperty(DS_FILTER_SNIPPETS)
        ? targetObj[DS_FILTER_SNIPPETS]
        : [];
      if (e.currentTarget.dataset.source === "icon") {
        this.props.triggerPDFViewerModal(e);
      } else if (!snippets || !snippets.length) {
        // let pdfLinkElement;
        // pdfLinkElement = document.querySelector(`.link.holder a[data-id="${toJS(targetObj).document_id}"]`);
        // pdfLinkElement.click();
        this.props.triggerPDFViewerModal(e);
      } else {
        this.props.store.setSelectedDocumentId(e.currentTarget.dataset.id);
        this.props.store.setPreviewActive(true);
        this.props.store.setSnippets(snippets);
      }

      this.setState({ activeIndex: e.currentTarget.dataset.id });
    } catch (ex) {
      console.error(ex);
    }
  };

  /**
   * @desc When a user selects the sort method from the dropdown,
   * subsequently the filter parameters are updated and a new search is triggered
   *
   * @param {event} e - React synthetic event
   */
  onSortSelect = (e) => {
    try {
      this.sortDropdownBtnRef.current.toggle();
      this.sortDropdownBtnConfig.defaultSelection =
        e.currentTarget.dataset.label;
      this.props.store.setFilters(
        "sort",
        "sort_by",
        e.currentTarget.dataset.key
      );
      this.props.makeFetchResultsApiCall();
    } catch (ex) {
      console.error(ex);
    }
  };

  /**
   * @desc Generates JSX code for the Header section of Results Viewer Card
   *
   * @returns {JSX} - Result viewer card header
   */
  makeHeaderContentJSX() {
    try {
      if (this.props.store.resultViewer.results) {
        if (this.props.store.resultViewer.results.hasOwnProperty("documents")) {
          if (this.props.store.resultViewer.results.documents.hits) {
            this.totalResults = parseInt(
              this.props.store.resultViewer.results.documents.total_result.value
            );
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return (
      <div className="card-header">
        <div className="results-count-container">
          <div className="results-count">
            RESULTS
            <span className="figure" ref={this.resultsCountFigureElemRef}>
              {"(" + this.totalResults + ")"}
            </span>
          </div>
          {/*
                        <div className="reset-to-default-button-container">
                            <button
                                className="reset-to-default-button"
                                onClick={this.props.resetPage}
                            >
                                <FontAwesomeIcon icon="undo" className="doc-search-reset-icon" />
                                <span className="reset-text">Reset</span>
                            </button>
                        </div>
                    */}
        </div>

        <Pagination
          store={this.props.store}
          makeFetchResultsApiCall={this.props.makeFetchResultsApiCall}
          totalResults={this.totalResults}
        />

        <div className="sort">
          <span className="label">Sort by</span>
          <DropdownSelect
            ref={this.sortDropdownBtnRef}
            onSortSelect={this.onSortSelect}
            config={this.sortDropdownBtnConfig}
          />
        </div>
      </div>
    );
  }

  /**
   * @desc Generates JSX code for the list items (results)
   * inside the Body section of Results Viewer Card
   *
   * @param {object} listItems - An array of search results
   * @returns {JSX} - Result list item
   */
  makeResultListItemsJSX(listItems) {
    return listItems.map((listItem, idx) => {
      // let title = (listItem.title) ? ((listItem.title.length > 0) ? tidyHTML(truncateWithElipsis(listItem.title, DS_RESULTS_TITLE_MAXLEN).replace('<!--b-->', '<b>')) : 'No Title') : 'No Title';
      let title = listItem.title ? listItem.title : "No Title";
      let releaseDate = listItem.release_date
        ? formatDate(listItem.release_date, DATE_FORMAT)
        : "";

      return (
        <li
          className={`${
            this.state.activeIndex === listItem.document_id
              ? "result-list-item active"
              : "result-list-item"
          } ${
            listItem?.report_type === "Podcast" &&
            this.state.activePlayer === idx
              ? "active-podcast"
              : ""
          }`}
          key={listItem.document_id}
          data-id={listItem.document_id}
          data-source={"result"}
          onClick={(e) => {
            this.setState({ activePlayer: idx });
            this.onResultListItemClick(e);
          }}
        >
          <div className="link holder">
            {listItem?.report_type === "Podcast" ? (
              <a href data-id={listItem.document_id} data-source={"icon"}>
                <FontAwesomeIcon
                  className={`${
                    listItem?.report_type === "Podcast" &&
                    this.state.activePlayer === idx
                      ? "active-podcast-color"
                      : ""
                  }`}
                  // className={`${}`}
                  // className={`${selectedPodcast?.ID == item.ID ? 'text-white' : ''}`}
                  icon={["far", "play-circle"]}
                />
              </a>
            ) : (
              <a
                href={`pdf-viewer/${listItem.document_id}`}
                target="_blank"
                data-id={listItem.document_id}
                data-source={"icon"}
                rel="noreferrer"
              >
                <FontAwesomeIcon icon="file-pdf" />
              </a>
            )}
          </div>
          <div
            data-id={`mid-panel-${idx}`}
            data-for={`mid-${this.state.randomId}`}
            data-tip={title}
            data-place="bottom"
            data-type="info"
            data-background-color="rgb(52,103,153)"
            data-multiline="true"
            className={`tooltipx title holder ${
              listItem?.report_type === "Podcast" &&
              this.state.activePlayer === idx
                ? "active-podcast-color"
                : ""
            }`}
          >
            {ReactHtmlParser(title)}
          </div>
          <ReactTooltip id={`mid-${this.state.randomId}`} />
          <div
            className={`date holder ${
              listItem?.report_type === "Podcast" &&
              this.state.activePlayer === idx
                ? "active-podcast-color"
                : ""
            }`}
          >
            {releaseDate}
          </div>
        </li>
      );
    });
  }

  /**
   * @desc Generates JSX code for State Elements, i.e the
   * states which can be toggled (loading, success, fail, empty)
   *
   * @param {string} element - State element type
   * @param {object} results - An array of search results
   * @returns {JSX} - State element
   */
  makeStateElementJSX(element, results = []) {
    switch (element) {
      case "loading":
        return (
          <div
            className={"state-element loading"}
            ref={this.loaderStateElemRef}
          >
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="secondary" />
          </div>
        );
      case "success":
        return (
          <div
            className={"state-element success"}
            ref={this.successStateElemRef}
          >
            <ul className="results-list">
              {this.makeResultListItemsJSX(results)}
            </ul>
          </div>
        );
      case "fail":
        return (
          <div className={"state-element fail"} ref={this.failStateElemRef}>
            <h3 className="placeholder-text">{/*SOMETHING WENT WRONG :(*/}</h3>
          </div>
        );
      case "empty":
        return (
          <div className={"state-element empty"} ref={this.emptyStateElemRef}>
            <h3 className="placeholder-text">{/*NO DOCUMENTS FOUND*/}</h3>
          </div>
        );
      default:
        return null;
    }
  }

  /**
   * @desc Generates JSX code for the Body section
   * of Results Viewer Card
   *
   * @returns {JSX} - Results box
   */
  makeBodyContentJSX() {
    let stateElement = null;

    //If an API call is ongoing, rendering will be blocked by a loader
    if (this.props.store.resultViewer.apiCallActive) {
      stateElement = this.makeStateElementJSX("loading");
    } else {
      if (this.props.store.resultViewer.results) {
        if (this.props.store.resultViewer.results.documents.hits.length > 0) {
          stateElement = this.makeStateElementJSX(
            "success",
            this.props.store.resultViewer.results.documents.hits
          );
        } else {
          stateElement = this.makeStateElementJSX("empty");
        }
      } else {
        stateElement = this.makeStateElementJSX("fail");
      }
    }

    return <div className="results-box">{stateElement}</div>;
  }

  render() {
    //Configs for spark card component
    this.resultViewerCardConfig.header.content = this.makeHeaderContentJSX();
    this.resultViewerCardConfig.body.content = this.makeBodyContentJSX();
    let activeClass = this.props.store.previewActive ? "" : "active";
    return (
      <div
        /* style={{
          ...(this.props?.auth?.user?.attributes["custom:restriction"] ===
          "Full Restriction"
            ? {
                width: "75%",
              }
            : { width: "50%" }),
        }} */
        className={`result-viewer-container ${activeClass} ${
          this.props.selectedPodcast ? "d-none d-lg-block" : ""
        } ${
          this.props?.auth?.user?.attributes["custom:restriction"] ===
          "Full Restriction"
            ? "full-screen"
            : "half-screen"
        }`}
      >
        <span className="instruction">
          CLICK ON A DOCUMENT FROM BELOW PANEL TO CONTINUE
        </span>
        <SparkCard config={this.resultViewerCardConfig} />
      </div>
    );
  }
}

export default observer(ResultViewer);
