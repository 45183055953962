import React, { Component } from "react";
import { observer } from "mobx-react";
import "./style.scss";
import MainSearch from "../MainSearch";
import AdvancedSearch from "../AdvancedSearch";
import SearchModuleStore from "../../stores/SearchModuleStore";
import { decorate, observable, action, toJS } from "mobx";
import {
  mainSearchConfig,
  advancedSearchConfig,
  filtersMapping,
} from "../../templates/DocumentSearch";
import DateRangeModule from "../DateRangeModule";
import common from "../../apis/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

decorate(SearchModuleStore, {
  advSearchPanelState: observable,
  syncingAdvSearch: observable,
  toggleAdvSearchPanel: action,
  hideAdvSearchPanel: action,
});

const searchModuleStore = new SearchModuleStore();
// eslint-disable-next-line no-unused-vars
let self = null;

class DocSearchFilters extends Component {
  constructor(props) {
    super(props);
    this.initialStartDate = new Date("2009-01-01");
    this.initialEndDate = new Date();
    self = this;
  }

  emptyAllAdvSearchTagInputs = () => {
    Object.keys(toJS(this.props.store.tagify.advanceSearch)).forEach((key) => {
      this.props.store.tagify.advanceSearch[key].removeAllTags();
    });
  };

  generatePayload = (obj, value) => {
    return {
      suggestion_text: value ? value : "",
      suggestion_type: obj.identifier.split(","),
      page: this.props.page,
      tab: this.props.tab,
    };
  };

  // sync adv search to main search.
  // remove existing tags from adv search
  // add tags in adv search
  onAdvSearchButtonClick = (event) => {
    let self = this;
    this.emptyAllAdvSearchTagInputs();
    try {
      let tags = this.props.store.tagify.mainSearch.input.getTagElms();
      if (!tags.length) {
        return;
      }
      tags.forEach((tag) => {
        let category = tag.getAttribute("category")
          ? tag.getAttribute("category")
          : "include_any";
        self.props.store.tagify.advanceSearch[category].addTags([
          {
            value: tag.getAttribute("value"),
            category: category,
          },
        ]);
      });
    } catch (e) {
      console.log(e);
    }
  };

  onSearchTrigger = () => {
    this.props.store.setCurrentPage(0);
    this.props.store.setTotalPages(0);
    this.props.store.setFilters("from", null, 0);
    this.props.store.setPreviewActive(false);
    this.props.makeFetchResultsApiCall();
  };

  onAdvSearchReset = () => {
    this.emptyAllAdvSearchTagInputs();
    this.props.store.tagify.mainSearch.input.removeAllTags();
    this.props.resetFilterValues();
    this.props.store.setCurrentPage(0);
    this.props.store.setTotalPages(0);
    this.props.makeFetchResultsApiCall();
  };

  updateDateFilters = (startDate, endDate, isDateRangeValid) => {
    this.props.store.setFilters("date_filter", null, {
      lower_bound: startDate,
      upper_bound: endDate,
      isDateRangeValid: isDateRangeValid,
    });
    this.onSearchTrigger();
  };

  updateFilters = (operation, identifier, value) => {
    let filtersJSON = toJS(this.props.store.filters);
    let filtersList =
      filtersJSON[filtersMapping[identifier].mainKey][
        filtersMapping[identifier].secondaryKey
      ];

    switch (operation) {
      case "ADD":
        filtersList.push(value);
        break;

      case "REMOVE":
        let index = filtersList.indexOf(value);
        if (index > -1) filtersList.splice(index, 1);
        break;
      default:
        break;
    }

    this.props.store.setFilters(
      filtersMapping[identifier].mainKey,
      filtersMapping[identifier].secondaryKey,
      filtersList
    );
  };

  render() {
    return (
      <div className="action-bar-container">
        <div className="salesRM">
          <span className="salesRMName">Sales RM: Harikesh Venkatachalam</span>
          <div className="salesRMIcon">
            <FontAwesomeIcon
              icon="envelope-open-text"
              onClick={(e) => this.handleContact(e, "mail")}
            />
          </div>
        </div>
        <div className="left-side-filters">
          <MainSearch
            type={1}
            {...this.props}
            filterType="mainSearch"
            className="main-search-input collapsed-input"
            searchModuleStore={searchModuleStore}
            mainSearchConfig={mainSearchConfig}
            apiObj={common}
            callbacks={{
              on_add: this.updateFilters,
              on_remove: this.updateFilters,
            }}
            sectors={this.props.sectors}
            onSearchTrigger={this.onSearchTrigger}
            generatePayload={this.generatePayload}
            defaultFilter="include_any"
          />
          <AdvancedSearch
            type={1}
            {...this.props}
            sectors={this.props.sectors}
            filterType="advanceSearch"
            searchModuleStore={searchModuleStore}
            advancedSearchConfig={advancedSearchConfig}
            apiObj={common}
            onAdvSearchButtonClick={this.onAdvSearchButtonClick}
            onAdvSearchReset={this.onAdvSearchReset}
            onSearchTrigger={this.onSearchTrigger}
            generatePayload={this.generatePayload}
            defaultFilter="include_any"
          />
        </div>
        <div className="mobile-element search-hint">
          (e.g. "Auto", or "Eicher Motors", or "Royal Enfield")
        </div>
        <div className="right-side-filters">
          <DateRangeModule
            startDate={this.initialStartDate}
            endDate={this.initialEndDate}
            dateFormat="dd-MMM-yyyy"
            startDateMin={new Date("2001-01-01")}
            startDateMax={new Date()}
            endDateMin={new Date("2001-01-01")}
            endDateMax={new Date()}
            onDateChange={this.updateDateFilters}
          />
        </div>
      </div>
    );
  }
}

export default observer(DocSearchFilters);
