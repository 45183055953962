import React, { Component } from "react";
import { observer } from "mobx-react";
import TagsInput from "../TagsInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";
import { getSession } from "../../utils/AuthUtils";
import axios from "axios";

class MainSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      salesRM: {
        name: null,
        email: null,
        phone_number: null,
      },
    };
  }

  componentDidMount() {
    this.getSalesRM(this.props.auth.user.attributes.email).then(() => {});
  }

  getSalesRM = async (email) => {
    const payload = { email: email };
    let session = await getSession();
    let config = { headers: { Authorization: session.accessToken.jwtToken } };
    this.makeGetSalesRMApiCall(
      `${process.env.REACT_APP_LIB_DOMAIN}/get_user_sales_rm`,
      payload,
      config
    )
      .then(this.onGetSalesRMSuccess)
      .catch(this.onGetSalesRMError);
  };

  onGetSalesRMSuccess = (res) => {
    const { salesRM } = this.state;
    if (res.status === 200) {
      if (res.data.status && res.data.data.Data.length) {
        salesRM.name = res.data.data.Data[0].UserName;
        salesRM.email = res.data.data.Data[0].Email;
        salesRM.phone_number = res.data.data.Data[0].Mobile;
        this.setState({ salesRM });
      } else {
        this.setDefaultRM();
      }
    } else {
      this.setDefaultRM();
    }
  };

  onGetSalesRMError = (err) => {
    console.log(err);
    this.setDefaultRM();
  };

  makeGetSalesRMApiCall = (url, payload, config) => {
    return axios.post(url, payload, config);
  };

  setDefaultRM = () => {
    const { salesRM } = this.state;
    salesRM.name = "Harikesh Venkatachalam";
    salesRM.email = "harikesh@sparkcapital.in";
    salesRM.phone_number = "9840442633";
    this.setState({ salesRM });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.props.onSearchTrigger();
    }
  };

  onMainSearchTagAdd = (e, identifier) => {
    let filterValue;
    filterValue = e.detail.data.value;
    e.detail.data["category"] = e.detail.tag.getAttribute("category");

    let tags = this.props.store.tagify.mainSearch.input.getTagElms();
    let valueMapping = {};
    if (tags.length > 1) {
      tags.forEach((tag) => {
        if (
          !valueMapping.hasOwnProperty(
            `${tag.getAttribute("value")}_${e.detail.data.category}`
          )
        ) {
          valueMapping[
            `${tag.getAttribute("value")}_${e.detail.data.category}`
          ] = 0;
        }
        if (
          tag.getAttribute("value") === e.detail.data.value &&
          tag.getAttribute("category") === e.detail.data.category
        ) {
          valueMapping[
            `${tag.getAttribute("value")}_${e.detail.data.category}`
          ] += 1;
          if (
            valueMapping[
              `${tag.getAttribute("value")}_${e.detail.data.category}`
            ] > 1
          ) {
            this.props.store.tagify.mainSearch.input.removeTag(tag);
            return;
          }
        }
      });
    }
    if (e.detail.data.category === "stock" && this.props.type === 1) {
      filterValue = e.detail.data.stock_id;
    } else if (e.detail.data.category === "stock" && this.props.type === 2) {
      filterValue = e.detail.data.value;
    }
    this.props.callbacks.on_add("ADD", e.detail.data.category, filterValue);
  };

  onMainSearchTagRemove = (e, identifier) => {
    let filterValue;
    filterValue = e.detail.data.value;
    if (!e.detail.data.hasOwnProperty("category") || !e.detail.data.category) {
      e.detail.data["category"] = this.props.defaultFilter;
    }
    if (e.detail.data.category === "stock" && this.props.type === 1) {
      filterValue = e.detail.data.stock_id;
    } else if (e.detail.data.category === "stock" && this.props.type === 2) {
      filterValue = e.detail.data.value;
    }
    this.props.callbacks.on_remove(
      "REMOVE",
      e.detail.data.category,
      filterValue
    );
  };

  handleContact = (e, mode) => {
    e.preventDefault();
    const link =
      mode === "mail"
        ? `mailto:${this.state.salesRM.email}`
        : `https://api.whatsapp.com/send?phone=+91${this.state.salesRM.phone_number}`;
    let a = document.createElement("a");
    a.href = link;
    a.setAttribute("target", "_blank");
    a.click();
  };

  render() {
    return (
      <div className="main-search-area">
        <div className="main-search-input-area">
          {/* <div className="main-search-input-area__salesRM-contact">
            <span>Sales RM: {this.state.salesRM.name}</span>
            <div className="main-search-input-area__salesRM-contact__icons">
              <FontAwesomeIcon
                icon="envelope-open-text"
                onClick={(e) => this.handleContact(e, "mail")}
              />
            </div>
          </div> */}
          <TagsInput
            sectors={this.props.sectors}
            auth={this.props.auth}
            store={this.props.store}
            config={this.props.mainSearchConfig}
            filterType="mainSearch"
            className={this.props.className}
            on_add={this.onMainSearchTagAdd}
            on_remove={this.onMainSearchTagRemove}
            onKeyPress={this.onKeyPress}
            expandOnhover={true}
            mode="input"
            defaultFilter={this.props.defaultFilter}
            apiObj={this.props.apiObj}
            generatePayload={this.props.generatePayload}
            suggestionsApiUrl={this.props.suggestionsApiUrl}
          />
          <button
            className="search-button"
            onClick={this.props.onSearchTrigger}
          >
            <FontAwesomeIcon icon="magnifying-glass" />
            <span className="search-button-text">SEARCH</span>
          </button>
        </div>
      </div>
    );
  }
}

export default observer(MainSearch);
