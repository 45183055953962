import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import DocSearchFilters from "../../components/DocSearchFilters";
import ResultViewer from "../../components/ResultViewer";
import Preview from "../../components/Preview";
import documentSearchApi from "../../apis/documentSearch";
import PDFViewerModal from "../../components/PDFViewerModal";
import ErrorBoundary from "../../ErrorBoundary";
import "./style.scss";
import {
  DS_ENDPOINT_FETCH_REPORTS,
  DS_TOAST_ID,
  MSG_AXIOS_NO_RESP_SERVER,
  MSG_AXIOS_REQUEST_ERROR,
  MSG_STH_WENT_WRONG_DS,
  TITLE_STH_WENT_WRONG,
  TOAST_TYPE_ERROR,
  AXIOS_ERR_CODE_TIMEOUT,
  MSG_AXIOS_TIMEOUT,
  ENDPOINT_GET_SUGGESTIONS,
} from "../../constants/strings";
import ResponseModal from "../../components/ResponseModal";
import { getParameterByName } from "../../utils/Browser";
import { checkLogOutSession, getSession } from "../../utils/AuthUtils";
import { Link } from "react-router-dom";
import FilterPanel from "../../components/FilterPanel/index";
import _ from "lodash";
import common from "../../apis/common";
import { toast } from "react-toastify";
import DocumentAudioPlayer from "../../components/PodcastNew/DocumentAudioPlayer";
import {
  fetch_reports,
  /* podcast_list, */
  podcast_list_link,
} from "../../templates/DocumentSearch/dummy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopupMessage from "./PopupMessage";

const queryString = require("query-string");

class DocumentSearch extends Component {
  constructor(props) {
    super(props);
    this.docSearchFiltersRef = React.createRef();

    this.state = {
      sectors: ["ALL"],
      selectedPodcast: null,
      isPlaying: false,
      restriction: "No Restriction",
      currentPage: 1,
      currentDocID: null,
      showPopup: localStorage.getItem("popupShown") === "1" ? false : true,
    };
    this.props.store.resetFilters();
    this.props.store.setSharedDocumentId("");
  }

  //Refs
  pdfViewerModalRef = React.createRef();
  openPDFNewTab = React.createRef();

  //Lifecycle methods
  handleClosePopup = () => {
    localStorage.setItem("popupShown", 1); // Set flag in local storage indicating popup has been shown
    this.setState({ showPopup: false }); // Hide the popup
  };

  /**
   * @desc Checking for authentication of the user, if he is authenticated, proceed to the initial
   * documents fetch call (default search parameters), else re-route him to the login module
   */
  async getSectors() {
    let user = this.props.auth.user;
    let sectors = user.attributes["custom:sectors"];
    let restriction;
    if (user.attributes["custom:restriction"]) {
      restriction = user.attributes["custom:restriction"];
    }
    try {
      if (sectors && sectors !== "") {
        sectors = JSON.parse(sectors);
      }
      // if (restriction == "Partial Restriction") {
      //     sectors = ["ALL"]
      // }
    } catch (err) {
      console.log("err", err);
      sectors = ["ALL"];
    }
    // let session = await getSession();
    // if (!session) {
    //     return;
    // }
    // const { data } = await common.post("/get_user_sectors",
    //     {
    //         email: user.attributes.email
    //     },
    //     { headers: { "Authorization": session.accessToken.jwtToken } }
    // );
    // const sectors = data?.data?.sectors || ["ALL"];
    this.setState({
      sectors: sectors,
      restriction: restriction,
    });
    this.setState({ sectors: sectors });
  }
  async getSectorsforDocument(document_id, rightTab) {
    let user = this.props.auth.user;
    if (
      user.attributes["custom:restriction"] === "Partial Restriction" &&
      rightTab
    ) {
      let session = await getSession();
      if (!session) {
        return;
      }
      const { data } = await common.post(
        "/document_search/fetch_sectors_using_documentid",
        {
          document_id: document_id,
        },
        { headers: { Authorization: session.accessToken.jwtToken } }
      );
      let sectors = user.attributes["custom:sectors"];
      try {
        if (sectors && sectors !== "") {
          sectors = JSON.parse(sectors);
        }
      } catch (err) {
        sectors = ["ALL"];
      }
      if (data?.data?.sectors?.length > 0) {
        const splitArray1 =
          data?.data?.sectors?.[0].split("/") || data?.data?.sectors;
        const isMatch = sectors.some((element) =>
          splitArray1.includes(element)
        );
        return isMatch;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  async componentDidMount() {
    // Timeout to prevent race condition from Login page
    await this.getSectors();
    setTimeout(async () => {
      this.props.amplitudeAnalytics.visit("Document Search");
      await checkLogOutSession();
    }, 2000);
    // this.dummyDataFunction()
    this.makeFetchResultsApiCall();
  }

  componentDidUpdate(prevProps, prevState) {
    let prevQueryParams = queryString.parse(prevProps.location.search, {
      ignoreQueryPrefix: true,
    });
    let queryParams = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (
      Object.keys(prevQueryParams).length &&
      !Object.keys(queryParams).length
    ) {
      this.resetPage();
    }
  }

  onClickOnPodcast = async (podcast) => {
    try {
      const checkPodcast = podcast_list_link?.filter(
        (d) => d.document_id === podcast?.document_id
      );
      if (checkPodcast?.length > 0) {
        this.setState({
          selectedPodcast: checkPodcast[0],
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  dummyDataFunction = async () => {
    try {
      this.fetchResultsConclude(fetch_reports.data);
    } catch (e) {}
  };
  /**
   * @desc Triggers a modal which houses a PDF viewer
   *
   * @param {event} e - React synthetic event
   */
  triggerPDFViewerModal = async (e, rightTab = false) => {
    try {
      if (e) e.preventDefault();
      this.setState({ selectedPodcast: null });
      this.props.store.setSelectedDocumentId(e.currentTarget.dataset.id);
      this.props.store.setSelectedDocumentSource(
        e.currentTarget.dataset.source
      );

      if (e.currentTarget.dataset.pagenum) {
        this.props.store.setSelectedSnippetPageNum(
          e.currentTarget.dataset.pagenum
        );
      }

      if (e.currentTarget.dataset.searchterms) {
        this.props.store.setSelectedSnippetSearchTerms(
          e.currentTarget.dataset.searchterms
        );
      }

      this.props.store.setTriggerForClientDownload(false);

      if (e.currentTarget.dataset.source === "preview") {
        this.pdfViewerModalRef.current.show();
      } else {
        let document_id = e.currentTarget.dataset.id;
        const data = await this.getSectorsforDocument(document_id, rightTab);
        if (!data) {
          toast.error(
            "You do not have access to this report. Please contact your RM"
          );
          return;
        }
        this.setState({ currentDocID: document_id }, () => {
          this.openPDFNewTab.current.click();
          if (
            this.props.store.resultViewer.results.documents.hits.length === 1 &&
            this.props.store.tagify.mainSearch.input.value.length === 0
          ) {
            this.resetFilterValues();
            this.makeFetchResultsApiCall();
          }
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  /**
   * @desc Calls the asynchronous method 'fetchResults', it is a wrapper method which
   * relieves the caller from the hassles of handling promises.
   */
  makeFetchResultsApiCall = () => {
    let documentId = getParameterByName("id", window.location.href);

    if (documentId) {
      if (documentId.length > 0) {
        let filters = toJS(this.props.store.filters);
        delete filters.date_filter.upper_bound;
        this.props.store.resetFilterValues(filters);
        this.props.store.setSharedDocumentId(documentId);
      }
    }

    this.fetchResults()
      .then(this.fetchResultsThenCallback)
      .catch(this.fetchResultsErrorCallback);
  };

  resetFilterValues = () => {
    window.history.pushState(
      "object or string",
      "Title",
      "/" +
        window.location.href
          .substring(window.location.href.lastIndexOf("/") + 1)
          .split("?")[0]
    ); // clear all params from url
    this.props.store.resetFilters();
    this.props.store.setSharedDocumentId("");
  };

  resetPage = () => {
    this.docSearchFiltersRef.current.onAdvSearchReset();
  };

  getStockTags() {
    //Find all stocks in Main Search Bar
    let mainSearchInput =
      document.getElementsByClassName("main-search-input")[0];
    let tagifyTags = mainSearchInput.getElementsByClassName("tagify__tag");
    let stockTags = [];
    tagifyTags = [...tagifyTags];
    tagifyTags = tagifyTags.length ? tagifyTags : [];
    tagifyTags.forEach((elem) => {
      if (elem.getAttribute("category") === "stock") {
        stockTags.push(
          elem.getElementsByClassName("tagify__tag-text")[0].innerText
        );
      }
    });

    return stockTags;
  }

  /**
   * @desc Method for making the API call to fetch
   * resultant documents against our search filter(search query)
   * @async
   * @returns {object} - Response object from the server
   */
  async fetchResults() {
    this.props.store.setResultViewer(true, null);
    this.props.store.setSnippets(null, true);
    this.props.store.setPreviewActive(false);

    if (this.props.store.sharedDocumentId.length > 0) {
      this.props.store.setFilters("document_filter", "document_ids", [
        ...this.props.store.filters.document_filter.document_ids,
        this.props.store.sharedDocumentId,
      ]);
      this.props.amplitudeAnalytics.sharedDocumentViewed({
        documentId: this.props.store.sharedDocumentId,
      });
      this.props.store.setSharedDocumentId("");
    }

    const payload = toJS(this.props.store.filters);
    let payload2 = toJS(this.props.store.filterPanel);
    let payload3 = _.merge(payload, payload2);
    // reallocating values due to reset by merging
    payload3["from"] = toJS(this.props.store.filters)["from"];
    payload3["sort"] = toJS(this.props.store.filters)["sort"];
    payload3["date_filter"] = toJS(this.props.store.filters)["date_filter"];
    let sectors = [];
    if (this.state.sectors?.length === 1) {
      if (this.state.sectors[0] !== "ALL") {
        sectors = this.state.sectors;
      }
    } else {
      sectors = this.state.sectors;
    }
    if (sectors?.length > 0) {
      const payload1Sectors = payload?.sector_filter?.sectors;
      const payload2Sectors = payload2?.sector_filter?.sectors;
      const payload3Sectors = this.state.sectors;
      const commonElements = [];
      if (payload1Sectors && payload1Sectors?.length > 0) {
        const commonElements1 = payload1Sectors.filter((sector) =>
          payload3Sectors.includes(sector)
        );
        commonElements.push(...commonElements1);
      }
      if (payload2Sectors && payload2Sectors?.length > 0) {
        const commonElements2 = payload2Sectors.filter((sector) =>
          payload3Sectors.includes(sector)
        );
        commonElements.push(...commonElements2);
      }
      if (commonElements.length === 0) {
        sectors = payload3Sectors;
      } else {
        sectors = commonElements;
      }
    }
    //for all permission
    if (this.state.sectors?.length === 1) {
      if (this.state.sectors[0] === "ALL") {
        const payload1Sectors = payload?.sector_filter?.sectors;
        const payload2Sectors = payload2?.sector_filter?.sectors;
        sectors.push(...payload1Sectors);
        sectors.push(...payload2Sectors);
      }
    }
    let loggingPayload = toJS(this.props.store.filters);

    loggingPayload.stock_filter.stocks = this.getStockTags();

    let session = await getSession();

    this.props.amplitudeAnalytics.setJwtToken(session.accessToken.jwtToken);

    // Timeout to prevent race condition from Visit event
    setTimeout(() => {
      this.props.amplitudeAnalytics.search(loggingPayload);
    }, 1000);

    return await documentSearchApi.post(
      DS_ENDPOINT_FETCH_REPORTS,
      {
        data: {
          ...payload3,
          sector_filter: {
            sectors: sectors,
          },
        },
      },
      { headers: { Authorization: session.accessToken.jwtToken } }
    );
  }

  //Callback methods

  /**
   * @desc Callback method fired when response from the async method 'fetchResults'
   * has been received successfully, it updates the store based on response data
   *
   * @param {object} response - Response from the api call
   */
  fetchResultsThenCallback = (response) => {
    if (response.status === 200 && response.hasOwnProperty("data")) {
      // 'OK'
      if (response.data.status && response.data.hasOwnProperty("data")) {
        //---Level 1
        this.fetchResultsConclude(response.data.data);
      } else {
        if (response.data.hasOwnProperty("error_message")) {
          this.props.toastify.fire(
            response.data.error_message,
            DS_TOAST_ID,
            TOAST_TYPE_ERROR
          );
        } else {
          this.props.toastify.fire(
            MSG_STH_WENT_WRONG_DS,
            DS_TOAST_ID,
            TOAST_TYPE_ERROR
          );
        }

        this.fetchResultsConclude(null);
      }
    } else {
      this.fetchResultsConclude(null);
    }
  };

  /**
   * @desc Callback method fired when an error is thrown
   * during the execution of async method 'fetchResults', this also
   * updates the store
   *
   * @param {object} error - Exception object
   */
  fetchResultsErrorCallback = (error) => {
    if (error.response) {
      this.props.toastify.fire(
        MSG_STH_WENT_WRONG_DS,
        DS_TOAST_ID,
        TOAST_TYPE_ERROR
      );
    } else if (error.request) {
      // The request was made but no response was received
      if (error.code === AXIOS_ERR_CODE_TIMEOUT) {
        this.props.toastify.fire(
          MSG_AXIOS_TIMEOUT,
          DS_TOAST_ID,
          TOAST_TYPE_ERROR
        );
      } else {
        this.props.toastify.fire(
          MSG_AXIOS_NO_RESP_SERVER,
          DS_TOAST_ID,
          TOAST_TYPE_ERROR
        );
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      this.props.toastify.fire(
        MSG_AXIOS_REQUEST_ERROR,
        DS_TOAST_ID,
        TOAST_TYPE_ERROR
      );
    }

    this.fetchResultsConclude(null);
  };

  /**
   * @desc The last method to be called during the fetch reports
   * procedure. Sets the results and snippets and finally marks the
   * api call active as false.
   *
   * @param {object} results - Fetched documents
   */
  fetchResultsConclude(results) {
    // results.documents.hits = [...results.documents.hits, ...podcast_list].sort(() => Math.random() - 0.5);//random code
    if (results && results.documents.hits.length) {
      this.props.store.setTotalPages(
        Math.ceil(
          parseInt(results.documents.total_result.value) /
            this.props.store.filters.size
        )
      );
      this.props.store.setCurrentPage(
        this.props.store.pagination.currentPage
          ? this.props.store.pagination.currentPage
          : 1
      );
    } else {
      this.props.store.setTotalPages(0);
      this.props.store.setCurrentPage(0);
    }
    this.props.store.setResultViewer(false, results);
    this.props.store.setSnippets(null, true);
  }

  render() {
    return (
      <Fragment>
        {/*This is the main app*/}
        <div className="main-app-container">
          {/*Action Bar that includes search and date range*/}
          <DocSearchFilters
            sectors={this.state.sectors}
            ref={this.docSearchFiltersRef}
            store={this.props.store}
            auth={this.props.auth}
            suggestionsApiUrl={ENDPOINT_GET_SUGGESTIONS}
            page="document_search"
            tab="all"
            makeFetchResultsApiCall={this.makeFetchResultsApiCall}
            resetFilterValues={this.resetFilterValues}
          />
          <div
            className={`${
              this.state.selectedPodcast ? "d-flex" : ""
            } d-lg-none customMargin`}
          >
            <button
              onClick={() => {
                this.setState({ selectedPodcast: null });
              }}
              className="back-to-button px-3"
            >
              <FontAwesomeIcon icon="fa-solid fa-angle-left" />
              <span className="ml-2">Go back to product list</span>
            </button>
          </div>
          {/*Result Viewer and Preview along-with required modals*/}
          <div className="result-preview-container">
            <ErrorBoundary
              render={(error, errorInfo) => (
                <ResponseModal
                  modal={true}
                  backdrop="static"
                  modalClass="response-modal"
                  titleClass="title error"
                  modalTitle={TITLE_STH_WENT_WRONG}
                  textClass="text"
                  modalText={errorInfo}
                  buttonClass="action-button error"
                  buttonText="Reload"
                  type="error"
                />
              )}
            >
              <FilterPanel
                fetchFilterResults={this.makeFetchResultsApiCall}
                toastify={this.props.toastify}
                store={this.props.store}
              />
              {/* <Preview
                                toastify={this.props.toastify}
                                store={this.props.store}
                                triggerPDFViewerModal={this.triggerPDFViewerModal}
                            /> */}
            </ErrorBoundary>

            {/*Result Viewer component*/}
            <ErrorBoundary
              render={(error, errorInfo) => (
                <ResponseModal
                  modal={true}
                  backdrop="static"
                  modalClass="response-modal"
                  titleClass="title error"
                  modalTitle={TITLE_STH_WENT_WRONG}
                  textClass="text"
                  modalText={errorInfo}
                  buttonClass="action-button error"
                  buttonText="Reload"
                  type="error"
                />
              )}
            >
              <ResultViewer
                makeFetchResultsApiCall={this.makeFetchResultsApiCall}
                toastify={this.props.toastify}
                auth={this.props.auth}
                selectedPodcast={this.state.selectedPodcast}
                store={this.props.store}
                onClickOnPodcast={this.onClickOnPodcast}
                triggerPDFViewerModal={this.triggerPDFViewerModal}
                resetPage={this.resetPage}
              />
            </ErrorBoundary>

            {/*Preview component*/}
            <ErrorBoundary
              render={(error, errorInfo) => (
                <ResponseModal
                  modal={true}
                  backdrop="static"
                  modalClass="response-modal"
                  titleClass="title error"
                  modalTitle={TITLE_STH_WENT_WRONG}
                  textClass="text"
                  modalText={errorInfo}
                  buttonClass="action-button error"
                  buttonText="Reload"
                  type="error"
                />
              )}
            >
              <>
                {this.state.selectedPodcast ? (
                  <DocumentAudioPlayer
                    isPlaying={this.state.isPlaying}
                    className="faded-background"
                    syncPlayingState={(s) => this.setState({ isPlaying: s })}
                    streamLink={this.state.selectedPodcast?.ORIGINAL_URL}
                    title={this.state?.selectedPodcast?.TITLE}
                    // handlePrev={() => null}
                    // handleNext={() => null}
                    options={{
                      autoplay: true,
                      tooltips: { controls: false, seek: true },
                      controls: [
                        "play-large",
                        "progress",
                        "current-time",
                        "mute",
                        "volume",
                        "captions",
                        "settings",
                      ],
                    }}
                  />
                ) : (
                  (this.props.store.previewActive !== true
                    ? this.props.auth.user.attributes["custom:restriction"] !==
                      "Full Restriction"
                    : true) && (
                    <Preview
                      toastify={this.props.toastify}
                      //makeFetchLastReadApiCall={this.makeFetchLastReadApiCall}
                      store={this.props.store}
                      triggerPDFViewerModal={this.triggerPDFViewerModal}
                    />
                  )
                )}
                {this.props.store.previewActive !== true && (
                  <Link
                    to={`pdf-viewer/${this.state.currentDocID}`}
                    target={"_blank"}
                    ref={this.openPDFNewTab}
                    style={{ display: "none" }}
                  />
                )}
              </>
            </ErrorBoundary>

            {/*PDF viewer modal*/}
            <PDFViewerModal
              store={this.props.store}
              amplitudeAnalytics={this.props.amplitudeAnalytics}
              auth={this.props.auth}
              toastify={this.props.toastify}
              ref={this.pdfViewerModalRef}
            />
          </div>
        </div>
        <PopupMessage
          isOpen={this.state.showPopup || false}
          onClose={this.handleClosePopup}
        />
      </Fragment>
    );
  }
}

export default observer(DocumentSearch);
