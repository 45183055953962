/* import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import Auth from "@aws-amplify/auth";
import config from "./config";

Auth.configure({
  mandatorySignId: true,
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
});

ReactDOM.render(<App />, document.getElementById("root")); */

import React from "react";
import ReactDOM from "react-dom/client"; // Update this import
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import Auth from "@aws-amplify/auth";
import config from "./config";

Auth.configure({
  mandatorySignId: true,
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
});

// Create a root
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the App
root.render(<App />);
