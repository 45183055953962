import React, { Component } from "react";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { observer } from "mobx-react";
import "./style.scss";
import validateForm from "../../utils/FormValidation";
import { createUser } from "./create-user-api";
import {
  CC_TOAST_ID,
  DS_TOAST_ID,
  MSG_COPIED_CLIPBOARD,
  MSG_NOT_COPIED_CLIPBOARD,
  TITLE_STH_WENT_WRONG,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
  TOAST_TYPE_SUCCESS,
} from "../../constants/strings";
import { checkUserInCognitoGroup } from "../../utils/AuthUtils";

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        first_name: {
          value: "",
          type: "text",
          label: "First Name",
          required: true,
          error: false,
          errorFeedback: "",
          invalid: false,
        },
        last_name: {
          value: "",
          type: "text",
          label: "Last Name",
          required: true,
          error: false,
          errorFeedback: "",
          invalid: false,
        },
        email: {
          value: "",
          type: "email",
          label: "Email",
          required: true,
          error: false,
          errorFeedback: "",
          invalid: false,
        },
        // allowed_to_download: {
        //     value: 'Yes',
        //     type: 'select',
        //     label: 'Allowed to download reports?',
        //     children: [<option>Yes</option>,<option>No</option>],
        //     required: true,
        //     error: false,
        //     errorFeedback: '',
        // },
        // password: {
        //     value: '',
        //     type: 'text',
        //     label: 'Password (Min 12 characters)',
        //     required: true,
        //     error: false,
        //     errorFeedback: '',
        //     invalid: false,
        // }
      },
      errorCount: 0,
      isFloatingLoaderVisible: false,
      isSuccessModalOpen: false,
      response: null,
      textToCopy: "",
    };
  }

  componentDidMount() {
    if (
      !this.props.auth.user ||
      !checkUserInCognitoGroup(
        this.props.auth.user,
        "spark-credential-creation-admins"
      )
    ) {
      this.props.history.push("/document_search");
    }
  }

  onInputChange = (e, key) => {
    let updateForm = this.state.form;
    updateForm[key].value = e.target.value;
    updateForm[key].error = false;
    updateForm[key].errorFeedback = "";
    updateForm[key].invalid = false;
    this.setState({ form: updateForm });
  };

  onKeyPress = () => {};

  createUserSubmit = () => {
    let { errorCount, form } = this.state;

    let newState = validateForm("createUser", this.state.form);

    console.log("newState", newState);

    this.setState({ form: newState }, () => {
      Object.keys(form).forEach((key) => {
        if (form[key].error) {
          errorCount = errorCount + 1;
        }
      });
    });

    this.setState({ errorCount }, () => {
      if (!errorCount) {
        this.setState({ isFloatingLoaderVisible: true });
        const payload = this.generatePayload();
        createUser(payload)
          .then(this.onCreateUserSuccess)
          .catch(this.onCreateUserFailure);
      }
    });
  };

  onCreateUserSuccess = (res) => {
    console.log(res);
    if (res.status === 200) {
      if (res.data.status) {
        this.setState({ isFloatingLoaderVisible: false });
        this.props.toastify.fire(
          res.data.data.content,
          CC_TOAST_ID,
          TOAST_TYPE_SUCCESS
        );
        // if(res.data.success_message) {
        //     if(res.data.success_message.toLowerCase() === "user already exists") {
        //         this.props.toastify.fire(res.data.success_message, CC_TOAST_ID, TOAST_TYPE_INFO);
        //         this.setState({isFloatingLoaderVisible: false});
        //         return
        //     }
        // }
        // this.setState({response: res.data.data, isFloatingLoaderVisible: false}, ()=> {
        //     this.toggleSuccessModal();
        //     this.generateTextToCopy();
        // })
      } else {
        this.setState({ isFloatingLoaderVisible: false });
        this.props.toastify.fire(
          res.data.error_message,
          CC_TOAST_ID,
          TOAST_TYPE_INFO
        );
      }
    }
  };

  onCreateUserFailure = (err) => {
    console.log(err);
    this.setState({ isFloatingLoaderVisible: false });
    this.props.toastify.fire(
      TITLE_STH_WENT_WRONG,
      CC_TOAST_ID,
      TOAST_TYPE_ERROR
    );
  };

  generatePayload = () => {
    const { form } = this.state;
    let payload = {};

    Object.keys(form).forEach((key) => {
      if (form[key].type === "select") {
        payload[key] = form[key].value === "Yes";
      } else if (key === "email") {
        payload[key] = form[key].value.toLowerCase();
      } else {
        payload[key] = form[key].value;
      }
    });
    payload["password"] = `${process.env.REACT_APP_COGNITO_PASSWORD}`;
    payload["allowed_to_download"] = true;
    return payload;
  };

  getFormTemplate = () => {
    const { form } = this.state;
    const formInputs = [];

    Object.keys(form).forEach((key, index) => {
      formInputs.push(
        <FormGroup
          key={`create-user-input-${index}`}
          className="create-user-form__group"
        >
          <div className="label-container">
            <span className="field-label">{form[key].label}</span>
          </div>
          <Input
            type={form[key].type}
            name={key}
            value={form[key].value}
            onChange={(e) => this.onInputChange(e, key)}
            onKeyPress={this.onKeyPress}
            invalid={form[key].invalid}
            children={form[key].children}
          />
          <FormFeedback>{form[key].errorFeedback}</FormFeedback>
        </FormGroup>
      );
    });

    return (
      <Form className="create-user-form">
        {formInputs}
        {/* <Button color='primary' onClick={this.getRandomPassword}>
                Generate Password
            </Button> */}
      </Form>
    );
  };

  getRandomPassword = () => {
    // const {form} = this.state;
    // let generator = require('generate-password');
    // let password = generator.generate({
    //     length: 12,
    //     numbers: true,
    //     symbols: true,
    //     strict: true
    // });
    // form.password.value = password;
    // this.setState({form});
  };

  getSuccessModalTemplate = () => {
    const { response } = this.state;
    return (
      <Modal isOpen={this.state.isSuccessModalOpen}>
        <ModalHeader className="d-flex align-items-center justify-content-center">
          User Created
        </ModalHeader>
        <ModalBody className="d-flex flex-column align-items-start success-modal-body">
          <span>
            Hi <b>{response.first_name},</b>
          </span>
          <span>
            To access Avendus Spark Portal visit https://sparkresearch.in
          </span>
          <span>
            Your user id is: <b>{response.email}</b>
          </span>
          <span>
            Your password is: <b>{response.password}</b>
          </span>
          <span>
            Please let me know if you are having any difficulties accessing the
            portal.
          </span>
          <span>Thanks.</span>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.toggleSuccessModal}>
            Close
          </Button>
          <Button
            className="ml-2"
            color="primary"
            onClick={() => this.copyText(this.state.textToCopy)}
          >
            Copy
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  toggleSuccessModal = () => {
    this.setState({ isSuccessModalOpen: !this.state.isSuccessModalOpen });
  };

  generateTextToCopy = () => {
    const { response } = this.state;
    let textToCopy = `Hi ${response.first_name},
            To access Avendus Spark Portal visit https://sparkresearch.in
            Your user id is: ${response.email}
            Your password is: ${response.password}
            Please let me know if you are having any difficulties accessing the portal.
            Thanks.`;
    this.setState({ textToCopy: textToCopy });
  };

  copyText = (text) => {
    try {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.props.toastify.fire(
        MSG_COPIED_CLIPBOARD,
        DS_TOAST_ID,
        TOAST_TYPE_SUCCESS
      );
    } catch (e) {
      console.error(e);
      this.props.toastify.fire(
        MSG_NOT_COPIED_CLIPBOARD,
        DS_TOAST_ID,
        TOAST_TYPE_ERROR
      );
    }
  };

  getFloatingLoaderTemplate = () => {
    return (
      <div className="create-user-floating-loader">
        <Spinner color="info" />
        <span>Creating..</span>
      </div>
    );
  };

  render() {
    const formTemplate = this.getFormTemplate();
    const successModal = this.state.response
      ? this.getSuccessModalTemplate()
      : null;
    const floatingLoader = this.state.isFloatingLoaderVisible
      ? this.getFloatingLoaderTemplate()
      : null;
    return (
      <div className="document-upload d-flex flex-column">
        <div className="upload-card create-user-card">
          <div className="card-header">
            <div className="card-title">Create User</div>
          </div>
          <div className="card-body">{formTemplate}</div>
          <div className="pb-4 d-flex justify-content-center">
            <Button onClick={() => this.createUserSubmit()} color="primary">
              Create
            </Button>
          </div>
        </div>

        {successModal}
        {floatingLoader}
      </div>
    );
  }
}

export default observer(CreateUser);
