import React from "react";
import { observer } from "mobx-react";
import { tidyHTML } from "../../utils/HTMLValidation";
import ReactHtmlParser from "react-html-parser";
import SparkCard from "../SparkCard";
import "./style.scss";
import {
  DS_TOAST_ID,
  MSG_NO_SNIPPETS,
  MSG_SNIPPETS_LOAD_ERROR,
  MSG_STH_WENT_WRONG_SNIPPETS,
  TOAST_TYPE_ERROR,
} from "../../constants/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileIcon from "../../img/file.svg";
import axios from "axios";
import { getSession } from "../../utils/AuthUtils";
import { Spinner } from "reactstrap";
import ReactTooltip from "react-tooltip";
// import { fetch_recommended_reports } from "../../templates/DocumentSearch/dummy";

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.emptyStateElemRef = React.createRef();
    this.successStateElemRef = React.createRef();
    this.failStateElemRef = React.createRef();
    this.state = {
      categoryElements: {
        most_read: {
          data: [],
          title: "Most Read",
          status: "loading",
        },
        /* last_read: {
                    data: [],
                    title: "Last 5 Reads",
                    status: "loading"
                }, */
        recommended: {
          data: [],
          title: "HOR's Picks",
          status: "loading",
        },
        randomID: String(Math.random()),
      },
      activeIndex: null,
    };
  }

  //Configs for child components
  previewCardConfig = {
    header: {
      show: true,
      content: null,
    },
    body: {
      content: null,
    },
    footer: {
      show: false,
      content: null,
    },
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.getReports("/document_search/fetch_recommended_reports").then(
      () => {}
    );
    //this.getReports('/document_search/fetch_last_read_reports').then(()=>{});
    this.getReports("/document_search/fetch_most_read_reports").then(() => {});
  };

  makeApiCall = (url, config) => {
    // return new Promise((resolve, reject) => {
    //     return resolve(fetch_recommended_reports)
    // })
    return axios.get(url, config);
  };

  getReports = async (endpoint) => {
    const { categoryElements } = this.state;
    let session = await getSession();
    let config = { headers: { Authorization: session.accessToken.jwtToken } };
    let section;

    switch (endpoint) {
      case "/document_search/fetch_recommended_reports":
        section = "recommended";
        break;
      /* case "/document_search/fetch_last_read_reports":
                section = "last_read";
                break; */
      case "/document_search/fetch_most_read_reports":
        section = "most_read";
        break;
      default:
        return;
    }

    categoryElements[section].status = "loading";

    this.makeApiCall(`${process.env.REACT_APP_LIB_DOMAIN}${endpoint}`, config)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            if (res.data.data.length) {
              categoryElements[section].data = res.data.data;
              categoryElements[section].status = "success";
            } else {
              categoryElements[section].status = "empty";
            }
          } else {
            categoryElements[section].status = "failure";
          }
        } else {
          categoryElements[section].status = "failure";
        }
        this.setState({ categoryElements });
      })
      .catch((err) => {
        console.log(err);
        categoryElements[section].status = "failure";
        this.setState({ categoryElements });
      });
  };

  /**
   * @desc Generates JSX code for the Header section of Preview Card
   *
   * @returns {JSX} - Preview card header
   */
  makeHeaderContentJSX() {
    return (
      <div className="card-header">
        <div className="partition top">
          <span className="card-title">Preview</span>
          <span onClick={this.closePreview} className="close-preview-btn">
            <FontAwesomeIcon
              id="close-preview-btn-ico"
              className="close-preview-btn-ico"
              icon="backspace"
            />
            <label htmlFor="close-preview-btn-ico">Back</label>
          </span>
        </div>
        <div className="partition bottom">
          <div className="card-subtitle">
            *Displays occurrences of free text search term in document
          </div>
        </div>
      </div>
    );
  }

  /**
   * @desc Generates JSX code for the snippets included
   * in the document selected
   *
   * @param {object} snippets - An array of snippets
   * @returns {JSX} - Snippet list items
   */
  makeSnippetsJSX(snippets) {
    try {
      if (snippets.length > 0) {
        return snippets.map((snippet, i) => {
          let sanitizedHTML = tidyHTML(
            snippet.highlight.replace("<!--b-->", "<b>")
          );
          let searchTerms = snippet.phrase_keyword.join(",");
          let pageNum = !isNaN(snippet.page_number)
            ? snippet.page_number + 1
            : 1;

          return (
            <li
              className="snippet-list-item"
              onClick={this.props.triggerPDFViewerModal}
              data-id={this.props.store.selectedDocumentId}
              data-source={`preview`}
              data-istriggerwatermark={true}
              data-pagenum={pageNum}
              data-searchterms={searchTerms}
              key={i}
            >
              <span className="page-num-wrapper">
                <label>Page {pageNum}</label>
              </span>
              <p className="snippet-content">
                {ReactHtmlParser(sanitizedHTML)}
              </p>
              <img src={fileIcon} alt="file-icon" className="snippet-icon" />
            </li>
          );
        });
      } else {
        // this.props.toastify.fire(MSG_NO_SNIPPETS, DS_TOAST_ID, TOAST_TYPE_ERROR);
      }
    } catch (e) {
      console.error(e);
      this.props.toastify.fire(
        MSG_STH_WENT_WRONG_SNIPPETS,
        DS_TOAST_ID,
        TOAST_TYPE_ERROR
      );
    }
  }

  onResultListItemClick = (e) => {
    try {
      this.props.triggerPDFViewerModal(e, true);
      this.setState({ activeIndex: e.currentTarget.dataset.id });
    } catch (ex) {
      console.error(ex);
    }
  };

  getSectionItemsJSX = (section, sectionNo) => {
    switch (this.state.categoryElements[section].status) {
      case "loading":
        return (
          <div
            className={"state-element loading w-100"}
            ref={this.loaderStateElemRef}
          >
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="secondary" />
          </div>
        );
      case "success":
        let sectionItems = [];
        this.state.categoryElements[section].data.forEach((item, idx) => {
          // let title = (item.title) ? ((item.title.length > 0) ? tidyHTML(truncateWithElipsis(item.title, DS_RESULTS_TITLE_MAXLEN).replace('<!--b-->', '<b>')) : 'No Title') : 'No Title';
          let title = item.title ? item.title : "No Title";
          sectionItems.push(
            <li
              className={
                this.state.activeIndex === item.document_id
                  ? "home-page-elements__section__item active"
                  : "home-page-elements__section__item"
              }
              key={item.document_id}
              data-id={item.document_id}
              data-source={"result"}
              onClick={this.onResultListItemClick}
            >
              <div className="link holder">
                <a
                  target="_blank"
                  href={`pdf-viewer/${item.document_id}`}
                  data-id={item.document_id}
                  data-source={"icon"}
                  rel="noreferrer"
                  // onClick={this.onResultListItemClick}
                >
                  <FontAwesomeIcon icon="file-pdf" />
                </a>
              </div>
              <div
                data-id={`third-panel-${idx}`}
                data-for={`third-${this.state.randomId}`}
                data-tip={title}
                data-place="bottom"
                data-type="info"
                data-background-color="rgb(52,103,153)"
                data-multiline="true"
                className=" tooltipx title holder"
              >
                {ReactHtmlParser(title)}
              </div>
              <ReactTooltip id={`third-${this.state.randomId}`} />
              {/* <div className="title holder">{ReactHtmlParser(title)}</div> */}
            </li>
          );
        });
        return sectionItems;
      case "failure":
        return (
          <div className={"state-element fail"} ref={this.failStateElemRef}>
            <h3 className="placeholder-text">{/*SOMETHING WENT WRONG :(*/}</h3>
          </div>
        );
      case "empty":
        return (
          <div className={"state-element empty"} ref={this.emptyStateElemRef}>
            <h3 className="placeholder-text">{/*NO DOCUMENTS FOUND*/}</h3>
          </div>
        );
      default:
        return;
    }
  };

  makePageElementJSX() {
    let elements = [];

    Object.keys(this.state.categoryElements).forEach((section, idx) => {
      elements.push(
        <div
          key={`page-element-${section}`}
          className="home-page-elements__section"
        >
          <h1 className="home-page-elements__section__heading">
            {this.state.categoryElements[section].title}
          </h1>
          {this.getSectionItemsJSX(section, idx)}
        </div>
      );
    });

    return elements;
  }

  /**
   * @desc Generates JSX code for State Elements, i.e the
   * states which can be toggled (loading, success, fail, empty)
   *
   * @param {string} element - State element type
   * @param {object} snippets - An array of snippets
   * @returns {JSX} - State element
   */
  makeStateElementJSX(element, snippets = []) {
    switch (element) {
      case "success":
        return (
          <div
            className={"state-element success active"}
            ref={this.successStateElemRef}
          >
            <ul className="snippets-list">{this.makeSnippetsJSX(snippets)}</ul>
          </div>
        );
      case "empty":
        return (
          <div
            className={"state-element empty active"}
            ref={this.emptyStateElemRef}
          >
            <h3 className="placeholder-text">{MSG_NO_SNIPPETS}</h3>
          </div>
        );
      case "placeholder":
        return (
          <div
            className={"state-element placeholder active"}
            ref={this.failStateElemRef}
          >
            <div className="home-page-elements">
              {this.makePageElementJSX()}
            </div>
          </div>
        );
      case "fail":
        return (
          <div
            className={"state-element fail active"}
            ref={this.failStateElemRef}
          >
            <h3 className="placeholder-text">{MSG_SNIPPETS_LOAD_ERROR}</h3>
          </div>
        );
      default:
        return null;
    }
  }

  /**
   * @desc Generates JSX code for the Body section
   * of Preview Card
   *
   * @returns {JSX} - Preview box
   */
  makeBodyContentJSX() {
    let successElement = null,
      emptyElement = null;

    //If we have set placeholder deliberately
    if (this.props.store.snippets.placeholder) {
      emptyElement = this.makeStateElementJSX("placeholder");
    } else {
      if (this.props.store.snippets.data) {
        if (this.props.store.snippets.data.length > 0) {
          successElement = this.makeStateElementJSX(
            "success",
            this.props.store.snippets.data
          );
        } else {
          successElement = this.makeStateElementJSX(
            "empty",
            this.props.store.snippets.data
          );
        }
      } else {
        successElement = this.makeStateElementJSX(
          "empty",
          this.props.store.snippets.data
        );
      }
    }

    return (
      <div className="preview-box">
        {successElement}
        {emptyElement}
      </div>
    );
  }

  closePreview = () => {
    this.props.store.setPreviewActive(false);
  };

  render() {
    //Configs for spark card component
    this.previewCardConfig.header.content =
      this.props.store.previewActive && this.makeHeaderContentJSX();
    this.previewCardConfig.body.content = this.makeBodyContentJSX();
    let activeClass = this.props.store.previewActive ? "active" : "";

    return (
      <div className={`preview-container ${activeClass}`}>
        <SparkCard config={this.previewCardConfig} />
      </div>
    );
  }
}

export default observer(Preview);
