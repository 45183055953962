import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../TablePagination/table-pagination.scss";

const PaginationNew = ({ currentPage, totalPages, onPageChange }) => {
  const onPrevButtonClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const onNextButtonClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const onPageInput = (e) => {
    const newPage = parseInt(e.target.value, 10);
    if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const onPageKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onPageInput(e);
    }
  };

  return (
    <div className="table-pagination">
      <button
        className="table-pagination__btn prev btn-primary"
        onClick={onPrevButtonClick}
        disabled={currentPage === 1}
      >
        <FontAwesomeIcon icon="caret-left" />
      </button>
      <div className="current-page table-pagination__current-page-wrapper">
        <input
          className="table-pagination__current-page-input current-page-input"
          type="number"
          value={currentPage}
          onChange={onPageInput}
          onKeyPress={onPageKeyPress}
        />
        <span className="mx-1" style={{ "font-size": "18px" }}>
          /
        </span>
        <span className="total-result" style={{ "font-size": "18px" }}>
          {totalPages}
        </span>
      </div>
      <button
        className="table-pagination__btn next btn-primary"
        onClick={onNextButtonClick}
        disabled={currentPage === totalPages}
      >
        <FontAwesomeIcon icon="caret-right" />
      </button>
    </div>
  );
};

export default PaginationNew;
